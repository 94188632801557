import {
    Column,
    textColumn,
    keyColumn,
    CellComponent,
} from 'react-datasheet-grid';
import clsx from 'clsx';
import {Helmet} from 'react-helmet';
import {useMemo, useState} from 'react';
import {VideoIcon} from '../components/core/icons';
import Datagrid from '../components/modules/dataGrid/dataGrid';
import * as styles from './playground.module.css';
import * as datagridSyles from '../components/modules/dataGrid/dataGrid.module.css';
import 'react-datasheet-grid/dist/style.css';

type TaskManagerVideoInformation = {
    [key: string]: string | null,
};

const defaultData: TaskManagerVideoInformation[] = [
    {
        title: 'LEGO® Mindstorms® EV3 insirace: HUDEBNÍ AUTOMAT - LEGO NĚCO NĚCO',
        mcbt: '556684222',
        theme: 'LEGO® Friends',
        channel: 'Commercial',
        tags: 'řada3, česky, Friends, czech, LEGO Friends, epiz..',
        description: 'řada3, česky, Friends, czech, LEGO Friends, epiz..',
    },
    {
        title: 'LEGO® Mindstorms® EV3 insirace: HUDEBNÍ AUTOMAT - LEGO NĚCO NĚCO',
        mcbt: '556684222',
        theme: 'LEGO® Friends',
        channel: 'Commercial',
        tags: 'řada3, česky, Friends, czech, LEGO Friends, epiz..',
        description: 'řada3, česky, Friends, czech, LEGO Friends, epiz..',
    },
    {
        title: 'LEGO® Mindstorms® EV3 insirace: HUDEBNÍ AUTOMAT - LEGO NĚCO NĚCO',
        mcbt: '556684222',
        theme: 'LEGO® Friends',
        channel: 'Commercial',
        tags: 'řada3, česky, Friends, czech, LEGO Friends, epiz..',
        description: 'řada3, česky, Friends, czech, LEGO Friends, epiz..',
    },
];

const defaultRow: TaskManagerVideoInformation = {
    title: null,
    mcbt: null,
    theme: null,
    channel: null,
    tags: null,
    description: null,
};

const StatusColumn: CellComponent = ({rowData}) => {
    const rowValues = Object.values(rowData);
    const nullContent = rowValues.filter(value => value === null);
    const hasError = nullContent.length > 0;
    const emptyRow = nullContent.length === rowValues.length;

    return (
        <div
            className={clsx(
                datagridSyles.statusColumn,
                hasError && datagridSyles.error,
                emptyRow && datagridSyles.empty,
            )}
        >
            <VideoIcon/>
        </div>
    );
};

const resolveCellClassName = ({rowData, columnId}: {rowData: TaskManagerVideoInformation; columnId?: string}) => {
    const rowValues = Object.values(rowData);
    const nullContent = rowValues.filter(value => value === null);
    const emptyRow = nullContent.length === rowValues.length;

    if (emptyRow || !columnId) {
        return undefined;
    }

    const hasError = rowData[columnId] === null;

    if (hasError) {
        return datagridSyles.cellError;
    }

    return undefined;
};

const PlaygroundDatagrid = () => {
    const [data, setData] = useState<TaskManagerVideoInformation[]>(
        [...defaultData, ...Array(100 - defaultData.length).fill(defaultRow)],
    );

    const columns: Column<TaskManagerVideoInformation>[] = useMemo(() => [
        {
            ...keyColumn<TaskManagerVideoInformation, 'title'>('title', textColumn),
            title: 'Title',
            width: 15,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<TaskManagerVideoInformation, 'mcbt'>('mcbt', textColumn),
            title: 'MCBT/AID',
            width: 4,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<TaskManagerVideoInformation, 'theme'>('theme', textColumn),
            title: 'Theme',
            width: 4,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<TaskManagerVideoInformation, 'channel'>('channel', textColumn),
            title: 'Channel',
            width: 4,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<TaskManagerVideoInformation, 'tags'>('tags', textColumn),
            title: 'Tags',
            width: 6,
            cellClassName: resolveCellClassName,
        },
        {
            ...keyColumn<TaskManagerVideoInformation, 'description'>('description', textColumn),
            title: 'Description',
            width: 6,
            cellClassName: resolveCellClassName,
        },
    ], []);

    return (
        <main className={styles.root}>
            <Helmet>
                <title>LEGO IX</title>
            </Helmet>
            <div className={styles.wrapper}>
                <Datagrid
                    title="Video informations"
                    columns={columns}
                    data={data}
                    onChange={setData}
                    settings={{
                        rowHeight: 40,
                        rowCount: 100,
                        gutterColumn: {
                            width: '0 0 50px',
                            minWidth: 50,
                            component: StatusColumn,
                        },
                    }}
                />
            </div>
        </main>
    );
};

export default PlaygroundDatagrid;
