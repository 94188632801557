import {
    Column,
    DataSheetGridRef,
    DynamicDataSheetGrid,
    SimpleColumn,
} from 'react-datasheet-grid';
import {Dispatch, SetStateAction, useRef} from 'react';
import {Operation} from 'react-datasheet-grid/dist/types';
import * as styles from './dataGrid.module.css';
import 'react-datasheet-grid/dist/style.css';
import {
    DirtyField,
    MassEditBackendErrors,
    MassEditBackendRelatedError,
    MassEditBackendRowError,
    PropertyValidation,
} from '../../../interfaces/general';
import {DataGridRow} from '../../../interfaces/dataGrid';

interface DataGridProps<T extends DataGridRow> {
    data: T[],
    originalData: T[],
    title?: string,
    columns: Column<T>[],
    onChange: (values: T[], operations: Operation[]) => void,
    settings: {
        rowHeight: number,
        rowCount: number,
        gutterColumn?: SimpleColumn<T, keyof T> | false,
    },
    dirtyFields: DirtyField<T>[],
    setDirtyFields: Dispatch<SetStateAction<DirtyField<T>[]>>,
    backendErrors: MassEditBackendErrors<T>,
    setBackendErrors: Dispatch<SetStateAction<MassEditBackendErrors<T>>>,
    updatedCells: Record<string, number>,
    errorCells: Record<string, number>,
    idIndexMap: Record<string, number>,
    setErrorMessage: Dispatch<SetStateAction<string>>,
    propertyValidation: PropertyValidation<T>,
}

const Datagrid = <T extends DataGridRow>({
    data,
    originalData,
    title,
    onChange,
    columns,
    settings: {
        rowHeight,
        rowCount,
        gutterColumn,
    },
    dirtyFields,
    backendErrors,
    updatedCells,
    errorCells,
    setErrorMessage,
}: DataGridProps<T>) => {
    const ref = useRef<DataSheetGridRef>(null);

    return (
        <div className={styles.wrapper}>
            {title && (
                <div className={styles.header}>
                    {title}
                </div>
            )}
            <DynamicDataSheetGrid
                ref={ref}
                className={styles.grid}
                rowClassName={({rowData}) => {
                    if (errorCells && errorCells[rowData.id as string] > 0) {
                        return 'datagrid-row__error';
                    }

                    if (updatedCells && updatedCells[rowData.id as string] > 0) {
                        return 'datagrid-row__edited';
                    }

                    return 'datagrid-row';
                }}
                value={data}
                onChange={(newValues, operations) => {
                    onChange(newValues, operations);
                }}
                onActiveCellChange={({cell}) => {
                    if (cell === null || cell.colId === undefined) {
                        setErrorMessage('');

                        return;
                    }

                    if (dirtyFields[cell.row] !== undefined
                        && dirtyFields[cell.row][cell.colId] !== undefined
                        && dirtyFields[cell.row][cell.colId].isValid === false
                    ) {
                        setErrorMessage(dirtyFields[cell.row][cell.colId].message);

                        return;
                    }

                    const backendError = backendErrors.errors[originalData[cell.row].id as string]?.find(item => item.field === cell.colId);

                    if (backendError !== undefined) {
                        setErrorMessage(backendError.message);

                        return;
                    }

                    const relatedIdArray = backendErrors.relatedIds[originalData[cell.row].id as string];

                    if (relatedIdArray !== undefined && relatedIdArray.some(item => item.field === cell.colId)) {
                        setErrorMessage('Another field is in conflict with this value.');

                        return;
                    }

                    setErrorMessage('');
                }}
                columns={columns}
                height={rowHeight * rowCount}
                rowHeight={rowHeight}
                gutterColumn={gutterColumn}
                lockRows
                disableExpandSelection
            />
        </div>
    );
};

export default Datagrid;
